import './App.css';
import { useState } from 'react';
import timeline from './events.json';
import { GiPartyPopper, GiPointySword } from 'react-icons/gi';
import {
  FaScroll,
  FaRegHandshake,
  FaInfo,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from 'react-icons/fa';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const MONTH_MAP = {
  1: 'Bhreianium',
  2: 'Heironeium',
  3: 'Halmyrium',
  4: 'Ehlonnai',
  5: 'Lirrai',
  6: 'Syrethai',
  7: 'Pelorium',
  8: 'Grumshium',
  9: 'Raoium',
  10: 'Lareox',
  11: 'Lolthox',
  12: 'Nerullox',
};

const REAL_MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

function formatDate(date) {
  return `${date.month} ${date.day}, ${date.year}`;
}

function sortableDateToReadable(date) {
  const [year, month, day] = date.split('-').map((part) => parseInt(part, 10));
  return <abbr title={formatDate({ year: year, month: REAL_MONTHS[month], day: day })}>
    {formatDate({ year: year, month: MONTH_MAP[month], day: day })}
  </abbr>;
}

function getIcon(type) {
  switch (type) {
    case 'start': return <GiPartyPopper title='Campaign Start' />;
    case 'battle': return <GiPointySword title='Battle' />;
    case 'join': return <FaRegHandshake title='New Party Member' />;
    case 'info': return <FaInfo title='Information' />;
    case 'event':
    default: return <FaScroll title='Event' />;
  }
}

function App() {
  const [events, setEvents] = useState(timeline.events);
  const [reversed, setReversed] = useState(true);
  return (
    <div className="App">
      <div>
        <button
          style={{
            padding: '10px',
            margin: '30px',
          }}
          title='Sort by Date'
          onClick={() => {
            setEvents(events.reverse());
            setReversed(!reversed);
          }}
        >
          {reversed ? <FaSortNumericDownAlt /> : <FaSortNumericUpAlt />}
        </button>
      </div>
      <VerticalTimeline
        lineColor='#333'
      >
        {events.map((event, index) => {
          return (
            <VerticalTimelineElement
              key={index}
              contentStyle={{ background: '#fff', color: '#222' }}
              contentArrowStyle={{ borderRight: '7px solid #fff' }}
              className={`vertical-timeline-element--work ${event.type}`}
              date={event.sortable}
              iconClassName={event.type}
              icon={getIcon(event.type)}
            >
              <h3 className="vertical-timeline-element-title">{event.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">{sortableDateToReadable(event.date)}</h4>
              <p>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`https://bhreia.com/campaigns/braving-bhreia/sessions/session-${event.session}/`}>
                  Session: {event.session}
                </a>, <small>campaign day {event.day}</small>.
              </p>
              <p>
                {event.text}
              </p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default App;
